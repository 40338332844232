import { forwardRef } from 'react';

import { Control, Controller } from 'react-hook-form';

import { Checkbox as BricksCheckbox, CheckboxProps as BricksCheckboxProps } from '@arrived/bricks';

export interface CheckboxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  name: string;
  checkboxProps?: BricksCheckboxProps;
  controlled?: boolean;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ control, checkboxProps, controlled, ...rest }, ref) => (
    <Controller
      control={control}
      render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => {
        const hasError = error && invalid;
        return (
          <BricksCheckbox
            ref={ref}
            name={name}
            id={name}
            checked={value}
            isError={hasError}
            {...checkboxProps}
            onCheckedChange={(checked) => {
              !controlled && onChange(checked);
              checkboxProps?.onCheckedChange?.(checked);
            }}
          />
        );
      }}
      {...rest}
    />
  ),
);
