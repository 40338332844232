import { Radio } from '@mui/material';

import { FormOptionListItem, FormOptionListItemProps } from './FormOptionListItem';

export type FormOptionRadioListItemProps<T> = Omit<FormOptionListItemProps<T>, 'SecondaryAction'>;

export const FormOptionRadioListItem = <T,>({ children, value, ...rest }: FormOptionRadioListItemProps<T>) => {
  return (
    <FormOptionListItem SecondaryAction={Radio} value={value} data-e2e="form-option-list-item" {...rest}>
      {children}
    </FormOptionListItem>
  );
};
