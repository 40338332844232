import { forwardRef, useCallback } from 'react';
import { TextInput } from 'react-native';

import { useControllableState } from '@tamagui/use-controllable-state';

import { useMaskedInputProps } from './input-mask';
import { InputControlFrame } from './InputControlFrame';

import type { InputProps, MaskedInputProps, UnmaskedInputProps } from './InputProps';

/**
 * Utility type guard to determine if the props passed to the input
 */
const isMaskedInputProps = (props: InputProps): props is MaskedInputProps => 'mask' in props;

/**
 * This is a controlled input component that is used for
 * masked text inputs. They use the `mask` prop to determine the
 * format of the input and the `onChangeText` callback.
 *
 * @see UseMaskedInputProps
 */
const MaskedInputControl = forwardRef<TextInput, MaskedInputProps>(
  (
    {
      mask,
      obfuscationCharacter,
      placeholderFillCharacter,
      showObfuscatedValue,
      maskAutoComplete,
      onChangeText,
      value: valueProp,
      ...props
    },
    forwardedRef,
  ) => {
    const [value, setValue] = useControllableState({
      prop: valueProp,
      defaultProp: '',
    });

    const composedOnChangeText = useCallback(
      (masked: string, unmasked: string, obfuscated: string) => {
        onChangeText?.(masked, unmasked, obfuscated);
        setValue(unmasked);
      },
      [onChangeText],
    );

    const maskedInputProps = useMaskedInputProps({
      value,
      mask,
      obfuscationCharacter,
      placeholderFillCharacter,
      showObfuscatedValue,
      maskAutoComplete,
      onChangeText: composedOnChangeText,
    });

    return <InputControlFrame ref={forwardedRef} {...maskedInputProps} {...props} />;
  },
);

/**
 * The default input that will be rendered when no mask value is passed.
 */
const UnmaskedInputControl = forwardRef<TextInput, UnmaskedInputProps>((props, forwardedRef) => (
  <InputControlFrame ref={forwardedRef} {...props} />
));

export const InputControl = forwardRef<TextInput, InputProps>((props, forwardedRef) => {
  if (isMaskedInputProps(props)) {
    return <MaskedInputControl ref={forwardedRef} {...props} />;
  }

  return <UnmaskedInputControl ref={forwardedRef} {...props} />;
});
