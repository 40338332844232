import { ReactNode, forwardRef } from 'react';

import { Control, Controller } from 'react-hook-form';

import {
  RadioGroup as BricksRadioGroup,
  RadioGroupProps as BricksRadioGroupProps,
  withStaticProperties,
} from '@arrived/bricks';

export interface RadioGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  name: string;
  children?: ReactNode;
  radioGroupProps?: BricksRadioGroupProps;
}

export const RadioGroup = withStaticProperties(
  forwardRef<HTMLButtonElement, RadioGroupProps>(({ control, children, radioGroupProps, ...rest }, ref) => (
    <Controller
      control={control}
      render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => {
        const hasError = Boolean(error) && invalid;
        return (
          <BricksRadioGroup
            ref={ref}
            name={name}
            value={value}
            onValueChange={onChange}
            isError={hasError}
            {...radioGroupProps}
          >
            {children}
          </BricksRadioGroup>
        );
      }}
      {...rest}
    />
  )),
  {
    Radio: BricksRadioGroup.Radio,
  },
);
