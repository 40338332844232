import { GetProps, styled } from '@tamagui/core';

import { UtilityText } from '../text';

export const InputText = styled(UtilityText, {
  name: 'InputText',
  token: 'utility.input',

  /**
   * Seems on native, the line height is too small even when
   * adjusted via the `UtilityText` style. This gets around the odd
   * spacing it creates.
   *
   * ! Original spacing: $16
   */
  '$platform-native': {
    lineHeight: '$18',
  },

  variants: {
    disabled: {
      true: {
        color: '$onSurface.neutral.muted',
      },
    },
  },
});

export type InputTextProps = GetProps<typeof InputText>;
