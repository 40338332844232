import { ForwardedRef, ReactElement, Ref, forwardRef, useCallback } from 'react';

import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldError,
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';

import { TamaguiElement, useComposedRefs } from '@tamagui/core';

import {
  Select as BricksSelect,
  SelectProps as BricksSelectProps,
  NotArray,
  withStaticProperties,
} from '@arrived/bricks';

import { CommonFormProps } from './CommonFormProps';

// TODO: This type is just copied from `react-hook-form` ^7.35.0, however, updating to get that type causes a whole suite of issues
//  across all of our apps so we are cheating right now and just stealing this type and can update the version of the library
//  later. See https://github.com/react-hook-form/react-hook-form/blob/master/src/types/path/eager.ts#L207
type FieldPathByValue<TFieldValues extends FieldValues, TValue> = {
  [Key in FieldPath<TFieldValues>]: FieldPathValue<TFieldValues, Key> extends TValue ? Key : never;
}[FieldPath<TFieldValues>];

type ControlledBricksSelectProps<T, Multiple extends boolean> = Omit<
  BricksSelectProps<T, Multiple>,
  'invalid' | 'value'
>;

type SelectFieldName<T, TFieldValues extends FieldValues, Multiple extends boolean> = Multiple extends true
  ? FieldPathByValue<TFieldValues, T[]>
  : FieldPathByValue<TFieldValues, NotArray<T>>;

interface SelectProps<T, TFieldValues extends FieldValues, Multiple extends boolean>
  extends ControlledBricksSelectProps<T, Multiple>,
    CommonFormProps {
  control: Control<TFieldValues>;
  name: SelectFieldName<T, TFieldValues, Multiple>;
}

// TODO: UnpackNestedValue was deprecated in `react-hook-form` ^7.33.0 but updating causes lots of issues. So leaving for now
//  and type hacking to get something that checks
type FieldPropsHacked<T, TFieldValues extends FieldValues, Multiple extends boolean> = {
  field: Omit<ControllerRenderProps<TFieldValues, SelectFieldName<T, TFieldValues, Multiple>>, 'value'> & {
    value: FieldPathValue<TFieldValues, SelectFieldName<T, TFieldValues, Multiple>>;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
};

type BricksSelectRenderProps<
  T,
  TFieldValues extends FieldValues,
  Multiple extends boolean,
> = ControlledBricksSelectProps<T, Multiple> & FieldPropsHacked<T, TFieldValues, Multiple> & CommonFormProps;

const BricksSelectRender = forwardRef(
  <T, TFieldValues extends FieldValues, Multiple extends boolean = false>(
    {
      children,
      field: { onChange: fieldOnChange, onBlur: fieldOnBlur, value, ref: fieldRef },
      fieldState: { invalid, error },
      multiple = false as Multiple,
      onBlur,
      onChange,
      errorMapping,
      ...rest
    }: BricksSelectRenderProps<T, TFieldValues, Multiple>,
    ref: ForwardedRef<TamaguiElement>,
  ) => {
    const bricksSelectRef = useComposedRefs(ref, fieldRef);

    const collectArrayOfErrors = useCallback(
      (callbackError: Record<string, FieldError>[]) => {
        if (!callbackError) {
          return 'Error validating selections';
        }

        /**
         * This is a nested object error that looks like this:
         * @example [
         *   {
         *     loanAmount: {
         *       message: "fixedRateInvestments[0].loanAmount cannot be null"
         *     }
         *   }
         * ]
         */

        // We want to flatten it to look like this:
        //fixedRateInvestments[0].loanAmount cannot be null
        return callbackError
          .map((e) => Object.entries(e).map(([_, value]) => value.message))
          .flat()
          .join(', ');
      },
      [error],
    );

    return (
      <BricksSelect
        value={value}
        onBlur={(event) => {
          fieldOnBlur();
          onBlur?.(event);
        }}
        invalid={invalid}
        multiple={multiple}
        onChange={(value) => {
          fieldOnChange(value);
          onChange?.(value);
        }}
        ref={bricksSelectRef}
        {...rest}
      >
        {error &&
          (multiple ? (
            <BricksSelect.Error>{Array.isArray(error) && collectArrayOfErrors(error)}</BricksSelect.Error>
          ) : (
            <BricksSelect.Error>
              {error.message ? (errorMapping ? errorMapping[error.message] : error.message) : error.message}
            </BricksSelect.Error>
          ))}
        {children}
      </BricksSelect>
    );
  },
) as <T, TFieldValues extends FieldValues, Multiple extends boolean = false>(
  props: BricksSelectRenderProps<T, TFieldValues, Multiple> & { ref?: Ref<TamaguiElement> },
) => ReactElement;

const SelectStatics = {
  Item: BricksSelect.Item,
  Label: BricksSelect.Label,
  Helper: BricksSelect.Helper,
  Start: BricksSelect.Start,
  End: BricksSelect.End,
} as const;

export const Select = withStaticProperties(
  forwardRef(
    <T, TFieldValues extends FieldValues, Multiple extends boolean = false>(
      { control, name, multiple = false as Multiple, errorMapping, ...rest }: SelectProps<T, TFieldValues, Multiple>,
      ref: ForwardedRef<TamaguiElement>,
    ) => (
      <Controller
        control={control}
        name={name}
        render={(props) => (
          <BricksSelectRender
            ref={ref}
            multiple={multiple}
            errorMapping={errorMapping}
            {...(props as FieldPropsHacked<T, TFieldValues, Multiple>)}
            {...rest}
          />
        )}
      />
    ),
  ),
  SelectStatics,
) as (<T, TFieldValues extends FieldValues, Multiple extends boolean = false>(
  props: SelectProps<T, TFieldValues, Multiple> & { ref?: Ref<TamaguiElement> },
) => ReactElement) &
  typeof SelectStatics;
