import { useCallback, useMemo } from 'react';
import { TextInputProps } from 'react-native';

import { InputMask, formatUsingMask } from './formatUsingMask';

export interface UseMaskedInputProps extends Omit<TextInputProps, 'onChangeText'> {
  /**
   * The array of either regex or string values, or a function that
   * returns the array of regex or string values.  We have two common
   * ones for Social Security Numbers and Employer Identification
   * Numbers. Exported as `SSN_MASK` and `EIN_MASK` respectively.
   *
   * @example [[/\d/], [/\d/], [/\d/], '-', [/\d/], [/\d/], '-', /\d/,
   * /\d/, /\d/, /\d/]
   */
  mask: InputMask;

  /**
   * Sub-callback that returns a masked, unmasked, and obfuscated value.
   * The obfuscated value is the masked value with the obfuscation
   * character. For example, if the mask is `SSN_MASK` and the
   * obfuscation character is `*`, the obfuscated value will be
   * `***-**-1234`.
   */
  onChangeText?: (rawValue: string, maskedValue: string, obfuscatedValue: string) => void;

  /**
   * Whether or not to display the obfuscated value on the `TextInput`.
   * Defaults to false
   */
  showObfuscatedValue?: boolean;

  /**
   * Character to be used as the "fill character" on the default
   * placeholder. Defaults to `_`
   * @default '_'
   */
  placeholderFillCharacter?: string;

  /**
   * Character to be used on the obfuscated characters. Defaults to `*`
   * @default '*'
   */
  obfuscationCharacter?: string;

  /**
   * Add next mask characters at the end of the value. Defaults to
   * `false`.
   *
   * @example
   * In a date mask, a input value of `"15/10"` will result:
   * - When set to false: `"15/10"`
   * - When set to true: `"15/10/"`
   *
   * @default false
   */
  maskAutoComplete?: boolean;
}

/**
 * Builds the properties needed for a common Input to have masked values
 * attached to it.
 */
export const useMaskedInputProps = ({
  value,
  mask,
  onChangeText,
  placeholderFillCharacter = '_',
  obfuscationCharacter,
  showObfuscatedValue,
  maskAutoComplete,
}: UseMaskedInputProps) => {
  const maskArray = useMemo(() => (typeof mask === 'function' ? mask(value) : mask), [mask, value]);

  const formattedValueResult = useMemo(
    () => formatUsingMask({ text: value || '', mask, obfuscationCharacter }),
    [mask, obfuscationCharacter, value],
  );

  const maskHasObfuscation = useMemo(
    () => maskArray && !!maskArray.find((maskItem) => Array.isArray(maskItem)),
    [maskArray],
  );

  const isValueObfuscated = useMemo(
    () => !!maskHasObfuscation && !!showObfuscatedValue,
    [maskHasObfuscation, showObfuscatedValue],
  );

  const handleChangeText = useCallback(
    (text: string) => {
      let textToFormat = text;

      if (isValueObfuscated) {
        textToFormat = formattedValueResult.masked || '';

        if (textToFormat.length > text.length) {
          textToFormat = textToFormat.slice(0, -1);
        } else if (textToFormat.length < text.length) {
          textToFormat = textToFormat + text[text.length - 1];
        }
      }

      const result = formatUsingMask({
        text: textToFormat,
        mask,
        obfuscationCharacter,
        maskAutoComplete: maskAutoComplete && textToFormat.length > formattedValueResult.masked.length,
      });

      onChangeText && onChangeText(result.unmasked, result.masked, result.obfuscated);
    },
    [isValueObfuscated, mask, obfuscationCharacter, onChangeText, formattedValueResult.masked, maskAutoComplete],
  );

  const defaultPlaceholder = useMemo(() => {
    if (maskArray) {
      return maskArray
        .map((maskChar) => {
          if (typeof maskChar === 'string') {
            return maskChar;
          } else {
            return placeholderFillCharacter;
          }
        })
        .join('');
    } else {
      return undefined;
    }
  }, [maskArray, placeholderFillCharacter]);

  const inputValue = isValueObfuscated ? formattedValueResult.obfuscated : formattedValueResult.masked;

  return {
    onChangeText: handleChangeText,
    value: inputValue,
    selection: isValueObfuscated ? { start: inputValue.length, end: inputValue.length } : undefined,
    placeholder: defaultPlaceholder,
  };
};
