import { ReactNode, forwardRef, useCallback } from 'react';
import { TextInput } from 'react-native';

import { Control, Controller } from 'react-hook-form';

import { useComposedRefs } from '@tamagui/core';

import {
  Input as BricksInput,
  InputProps as BricksInputProps,
  FormErrorProps,
  FormHelperTextProps,
  FormLabelProps,
  withStaticProperties,
} from '@arrived/bricks';

import { CommonFormProps } from './CommonFormProps';

export interface InputProps extends CommonFormProps {
  label?: ReactNode;
  helperText?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  name: string;

  /**
   * Intercepts the render and allows to format the value before it is
   * displayed.  This has _no_ effect on the underlying value, only the
   * display. For example, if you are using a currency input and want to
   * add a dollar sign, you can use this prop with (for example)
   * `formatCurrencyInput` from `@arrived/common`.
   */
  interceptRender?: (val: string) => string | number;

  /**
   * Intercepts the `onChangeText` and allows to format the value before
   * it is set within the form state. For example, if you are using a
   * currency input and want to remove non-numeric characters, you can
   * use this prop.
   */
  interceptChange?: (val: string) => string | number;
  children?: ReactNode;
  inputProps?: BricksInputProps & {
    /**
     * If you want to mask the value, you can use this prop which will
     * grab the second argument from `onChangeText` when a `mask` prop
     * is present.
     */
    useMaskedValue?: boolean;
  };
  labelProps?: FormLabelProps;
  helperTextProps?: FormHelperTextProps;
  errorProps?: FormErrorProps;
  showError?: boolean;
  silenceErrorKeys?: string[];
  errorOverride?: ReactNode;
}

export const Input = withStaticProperties(
  forwardRef<TextInput, InputProps>(
    (
      {
        children,
        control,
        label,
        helperText,
        labelProps,
        helperTextProps,
        errorProps,
        inputProps,
        interceptRender,
        interceptChange,
        showError,
        silenceErrorKeys,
        errorMapping,
        errorOverride,

        ...rest
      },
      ref,
    ) => (
      <Controller
        control={control}
        render={({ field: { value, onChange, onBlur, name, ref: fieldRef }, fieldState: { invalid, error } }) => {
          const isError =
            Boolean(errorOverride) || (showError !== false && error && !silenceErrorKeys?.includes(error.type));

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const bricksInputRef = useComposedRefs(ref, fieldRef);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const handleChangeText = useCallback(
            (value: string, maskedValue?: string) => {
              let setValue = value;

              if (inputProps && inputProps.mask && inputProps.useMaskedValue) {
                setValue = maskedValue || value;
              }

              onChange(interceptChange ? interceptChange(setValue) : setValue);
            },
            [onChange, interceptChange, inputProps],
          );

          return (
            <BricksInput
              aria-label={name}
              onBlur={onBlur}
              value={interceptRender ? interceptRender(value) : value}
              onChangeText={handleChangeText}
              isInvalid={isError || invalid}
              id={name}
              ref={bricksInputRef}
              {...inputProps}
            >
              {label && (
                // eslint-disable-next-line
                // @typescript-eslint/ban-ts-comment @ts-ignore htmlFor
                // is valid for the web
                <BricksInput.Label htmlFor={name} {...labelProps}>
                  {label}
                </BricksInput.Label>
              )}
              {helperText && <BricksInput.Helper {...helperTextProps}>{helperText}</BricksInput.Helper>}
              {errorOverride && <BricksInput.Error {...errorProps}>{errorOverride}</BricksInput.Error>}
              {!errorOverride && isError && (
                <BricksInput.Error {...errorProps}>
                  {error &&
                    (error.message
                      ? errorMapping && errorMapping[error.message]
                        ? errorMapping[error.message]
                        : error.message
                      : error.message)}
                </BricksInput.Error>
              )}
              {children}
            </BricksInput>
          );
        }}
        {...rest}
      />
    ),
  ),
  { Start: BricksInput.Start, End: BricksInput.End, Helper: BricksInput.Helper, HelperExtra: BricksInput.HelperExtra },
);
