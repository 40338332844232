import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData } from 'react-native';

import { GetProps, composeEventHandlers, styled, useComposedRefs } from '@tamagui/core';

import { useInputRefContext } from './InputRefContext';

const FORM_INPUT_CONTROL_NAME = 'InputControl';

/**
 * The rendered input field
 */
const InputControlStyled = styled(
  TextInput,
  {
    name: FORM_INPUT_CONTROL_NAME,

    flex: 1,

    color: '$onSurface.neutral.default',
    placeholderTextColor: '$onSurface.neutral.muted',

    outlineStyle: 'solid',
    outlineWidth: 0,
  },
  {
    isInput: true,
  },
);

export type InputControlFrameProps = GetProps<typeof InputControlStyled>;

export const InputControlFrame = InputControlStyled.styleable(
  ({ onFocus, onBlur, onKeyPress, ...props }, forwardedRef) => {
    const { ref, setIsFocused } = useInputRefContext();
    const composedRefs = useComposedRefs(forwardedRef, ref);

    return (
      <InputControlStyled
        accessible
        {...props}
        ref={composedRefs}
        onKeyPress={composeEventHandlers(onKeyPress, (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
          e.persist();
        })}
        onFocus={composeEventHandlers(onFocus, () => setIsFocused(true))}
        onBlur={composeEventHandlers(onBlur, () => setIsFocused(false))}
      />
    );
  },
);
