import { KeyboardEventHandler, useCallback } from 'react';

import {
  GetProps,
  SizeTokens,
  Stack,
  createStyledContext,
  isWeb,
  styled,
  useProps,
  withStaticProperties,
} from '@tamagui/core';
import { RadioGroup as TamaRadioGroup } from '@tamagui/radio-group';

const RadioGroupStyledContext = createStyledContext({
  isError: false,
  isDisabled: false,
});

const RADIO_BUTTON_WRAPPER_NAME = 'RadioButtonWrapper';

const RadioButtonWrapper = styled(Stack, {
  name: RADIO_BUTTON_WRAPPER_NAME,
  zIndex: 1,
});

const getRadioSize = (size: SizeTokens) => {
  const dimension = size;

  if (!size || size === '$true') {
    return {
      value: '',
      h: '$4',
      w: '$4',
    };
  }

  return {
    value: '',
    h: dimension,
    w: dimension,
  };
};

const RadioGroupMainContainer = styled(TamaRadioGroup, {
  context: RadioGroupStyledContext,
  variants: {
    isDisabled: { true: {} },
    isError: {
      true: {},
    },
  } as const,
});

const RadioGroupMain = RadioGroupMainContainer.styleable((props, ref) => {
  const activeProps = useProps(props);
  return (
    <RadioGroupMainContainer
      ref={ref}
      {...activeProps}
      isDisabled={activeProps.disabled || activeProps.isDisabled || false}
      isError={activeProps.isError}
    />
  );
});

const RadioButton = styled(TamaRadioGroup.Item, {
  context: RadioGroupStyledContext,
  value: '',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 1000,
  borderWidth: '$0.25',
  outlineWidth: 0,
  backgroundColor: '$transparent',
  borderColor: '$interactive.neutral.rested',
  focusStyle: {
    borderWidth: '$0.25',
    borderColor: '$interactive.primary.rested',
  },
  pressStyle: {
    borderWidth: '$0.25',
    borderColor: '$interactive.neutral.hovered',
  },
  variants: {
    size: getRadioSize,
    isError: {
      true: {
        value: '',
        borderWidth: '$0.25',
        borderColor: '$onSurface.negative.outline',
      },
    },
    isDisabled: {
      true: {
        value: '',
        pointerEvents: 'none',
        borderWidth: '$0.25',
        borderColor: '$interactive.neutral.disabled',
      },
    },
  } as const,
  defaultVariants: {
    size: '$true',
  },
});

const RadioIndicator = styled(TamaRadioGroup.Indicator, {
  context: RadioGroupStyledContext,
  unstyled: true,
  width: '100%',
  height: '100%',
  borderRadius: 1000,
  borderColor: '$interactive.neutral.rested',
  outlineColor: '$interactive.neutral.rested',
  outlineWidth: '$1',
  outlineOffset: -3.8,
  outlineStyle: 'solid',
  borderWidth: '$1',
  zIndex: -1,
  variants: {
    isError: {
      true: {
        borderColor: '$onSurface.negative.outline',
        outlineColor: '$onSurface.negative.outline',
      },
    },
    isDisabled: {
      true: {
        pointerEvents: 'none',
        borderColor: '$interactive.neutral.disabled',
        outlineColor: '$interactive.neutral.disabled',
      },
    },
  } as const,
});

const RadioMain = RadioButton.styleable((props, ref) => {
  const activeProps = useProps(props);
  const { isDisabled: isRadioGroupDisabled } = RadioGroupStyledContext.useStyledContext();
  const isDisabled = activeProps.disabled || activeProps.isDisabled || isRadioGroupDisabled;
  const handleDisabledKeyDown = useCallback<KeyboardEventHandler<HTMLButtonElement>>(
    (e) => {
      if (isDisabled) e.preventDefault();
    },
    [isDisabled],
  );

  return (
    <RadioButtonWrapper>
      <RadioButton
        ref={ref}
        value=""
        focusable={!isDisabled}
        {...(isWeb && {
          onKeyDown: handleDisabledKeyDown,
        })}
        {...activeProps}
      >
        <RadioIndicator />
      </RadioButton>
    </RadioButtonWrapper>
  );
});

export const RadioGroup = withStaticProperties(RadioGroupMain, {
  Radio: RadioMain,
});

export type RadioGroupProps = GetProps<typeof RadioGroupMainContainer>;
