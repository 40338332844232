import { RefObject, createContext, useContext } from 'react';
import { TextInput } from 'react-native';

type InputContextValue = {
  /**
   * Direct ref to the input field, this will control the
   * actions of the input field.
   */
  ref?: RefObject<TextInput>;

  /**
   * Set the focus state of the input field.
   */
  setIsFocused: (focused: boolean) => void;
};

/**
 * When using Inputs, we need to keep track of their general states.
 * This hook keeps track of whether in the input is focused and the ref
 * associated.
 */
export const InputRefContext = createContext<InputContextValue>({ ref: undefined, setIsFocused: () => undefined });

/**
 * Hook to access the input context.
 */
export const useInputRefContext = () => {
  const context = useContext(InputRefContext);

  if (!context) {
    throw new Error('useInputRefContext must be used within a InputRefContextProvider');
  }

  return context;
};
