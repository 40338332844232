import { Ref, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextInput } from 'react-native';

import { composeEventHandlers, withStaticProperties } from '@tamagui/core';

import { WarningIcon } from '../../../icons';
import { Stack } from '../../layout';
import { FormEndInputAdornment, FormStartInputAdornment } from '../adornment';
import { FormError } from '../error';
import { FormHelperExtra, FormHelperText } from '../helper';
import { InputFrame } from '../InputFrame';
import { InputFrameStyledContext } from '../InputStyledContext';
import { InputText } from '../InputText';
import { FormLabel } from '../label';
import { useComposedChildren } from '../useComposedChildren';

import { InputControl } from './InputControl';
import { InputRefContext } from './InputRefContext';

import type { InputProps } from './InputProps';

const InputMain = (
  {
    children,
    disabled = false,
    isInvalid,
    frameProps = {},
    textProps,
    containerProps,
    controlContainerProps,
    hideWarningIcon,
    ...props
  }: InputProps,
  forwardedRef: Ref<TextInput>,
) => {
  const inputFieldRef = useRef<TextInput>(null);
  const [isFocused, setIsFocused] = useState(false);

  const composedChildren = useComposedChildren(children);

  const isError = useMemo(
    () => Boolean(composedChildren?.formComponents.errorComponent),
    [composedChildren?.formComponents.errorComponent],
  );

  const hasStartAdornment = useMemo(
    () => Boolean(composedChildren?.formComponents.startAdornments?.length),
    [composedChildren?.formComponents.startAdornments?.length],
  );

  const hasEndAdornment = useMemo(
    () => Boolean(composedChildren?.formComponents.endAdornments?.length) || isInvalid,
    [composedChildren?.formComponents.endAdornments?.length, isInvalid],
  );

  const handleFocusInput = useCallback(() => {
    inputFieldRef.current?.focus();
  }, [inputFieldRef]);

  const inputRefContextValues = useMemo(
    () => ({
      ref: inputFieldRef,
      setIsFocused,
    }),
    [inputFieldRef],
  );

  useEffect(() => {
    if (isFocused) {
      inputFieldRef.current?.focus();
    } else {
      inputFieldRef.current?.blur();
    }
  }, [isFocused]);

  return (
    <InputFrameStyledContext.Provider
      isFocused={isFocused}
      isInvalid={isInvalid || isError}
      isError={isError}
      isDisabled={disabled}
    >
      <InputRefContext.Provider value={inputRefContextValues}>
        <Stack className="input-container" {...containerProps}>
          {composedChildren?.formComponents.labelComponent}

          <Stack className="control-container" {...controlContainerProps}>
            <InputFrame {...frameProps} onPress={composeEventHandlers(handleFocusInput, frameProps.onPress)}>
              {composedChildren?.formComponents.startAdornments}

              <InputText asChild {...textProps}>
                <InputControl
                  ref={forwardedRef}
                  disabled={disabled}
                  pl={hasStartAdornment ? '$2' : '$4'}
                  pr={hasEndAdornment ? '$2' : '$4'}
                  {...props}
                />
              </InputText>

              {composedChildren?.formComponents.endAdornments}

              {isInvalid && !hideWarningIcon && (
                <FormEndInputAdornment
                  mr="$4"
                  pl="$2"
                  borderLeftWidth="$0.25"
                  borderColor="$onSurface.neutral.zebraAlt"
                >
                  <WarningIcon color="$onSurface.negative.outline" />
                </FormEndInputAdornment>
              )}
            </InputFrame>

            {composedChildren?.formComponents.errorComponent}
          </Stack>

          <Stack row>
            {composedChildren?.formComponents.helperComponent}
            {composedChildren?.formComponents.helperExtraComponent}
          </Stack>

          {composedChildren?.children}
        </Stack>
      </InputRefContext.Provider>
    </InputFrameStyledContext.Provider>
  );
};

export const Input = withStaticProperties(forwardRef(InputMain), {
  Start: FormStartInputAdornment,
  End: FormEndInputAdornment,
  Label: FormLabel,
  Error: FormError,
  Helper: FormHelperText,
  HelperExtra: FormHelperExtra,
});
