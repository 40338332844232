import { useCallback } from 'react';

import { Checkbox } from '@mui/material';

import { FormOptionListItem, FormOptionListItemProps, SecondaryActionProps } from './FormOptionListItem';

export const FormOptionCheckboxListItem = ({
  children,
  id,
  value,
  onChange,
  ...rest
}: Omit<FormOptionListItemProps<boolean>, 'isSelected' | 'SecondaryAction'>) => {
  const handleOnChange = useCallback((value: boolean) => onChange(!value), [onChange]);

  const SecondaryAction = useCallback(
    ({ onChange, value }: SecondaryActionProps<boolean>) => (
      <Checkbox onChange={onChange} checked={value} inputProps={{ 'aria-labelledby': id }} />
    ),
    [],
  );

  return (
    <FormOptionListItem
      isSelected={value}
      onChange={handleOnChange}
      SecondaryAction={SecondaryAction}
      value={value}
      id={id}
      {...rest}
    >
      {children}
    </FormOptionListItem>
  );
};
